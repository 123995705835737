import React, { Component } from 'react';
import { HashRouter, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import ExternalRoute from './ExternalRoute';
import PrivateRoute from './PrivateRoute';

const loading = () => <div className="animated fadeIn pt-3 text-center wave-white"></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login/Login'));

class App extends Component {

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <ExternalRoute exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <PrivateRoute path="/" name="Home" render={props => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
